import { FlagParams } from './../../../../resource/model/database/material-list.d';
import { ChargeUnitEnum } from './../../../../resource/enum/charge-unit';
import { classifyService, supplierProductService } from '@/api';
import { ApiResponse } from '@/api/axios';
import { Paging } from '@/api/base';
import { OsTable, OsTableQuery, OsPagination, OsTableOperation } from '@/components';
import { OperationOption } from '@/components/os-table-operation/os-table-operation';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import { OsTableOption, OsTableColumn, RowOperation } from '@/components/os-table/os-table';
import { ResourceStatusEnum } from '@/resource/enum';
import { SupplierProductResource, ProductList, ImportRes } from '@/resource/model';
import {
  getStatusClass,
  getStatusI18Key,
  translation,
  handleImportError,
  showWarningConfirm,
  messageError,
  downloadFileByBlob
} from '@/utils';
import { ImportFile, SelectMaterial } from '@/views/dialogs';
import { Message } from 'element-ui';
import { Component, Vue, Watch } from 'vue-property-decorator';
// import BatchAddSupplierProduct from './batch-add-supplier-product/batch-add-supplier-product.vue';

@Component({
  name: 'SupplierProduct',
  components: {
    OsTable,
    OsTableQuery,
    OsPagination,
    OsTableOperation,
    ImportFile,
    SelectMaterial
  }
})
export default class SupplierProduct extends Vue {
  /**
   * 表格配置
   */
  public tableOption: OsTableOption<SupplierProductResource> = {
    loading: false,
    data: [],
    fit: true,
    defaultSort: { prop: 'createTime', order: 'descending' }
  };
  /**
   * 表格列配置
   */
  public tableColumnOptions: Array<OsTableColumn<SupplierProductResource>> = [
    {
      type: 'selection',
      prop: 'id',
      label: '',
      reserveSelection: true
    },
    {
      prop: 'productCode',
      label: 'supplierProduct.productCode',
      minWidth: '120px',
      showOverflowTooltip: true
    },
    {
      prop: 'categoryName',
      label: 'supplierProduct.categoryName',
      minWidth: '150px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        const data: ProductList = row as ProductList;

        const list = [];
        if (data.categoryName) {
          list.push(data.categoryName);
        }
        if (data.secondCategoryName) {
          list.push(data.secondCategoryName);
        }

        return list.join(' / ');
      }
    },
    {
      prop: 'productName',
      label: 'supplierProduct.productName',
      minWidth: '400px',
      showOverflowTooltip: true
    },
    {
      prop: 'price',
      label: 'supplierProduct.price',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'priceUnitName',
      label: 'supplierProduct.priceUnit',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'status',
      label: 'common.status',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'remark',
      label: 'common.remark',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'createTime',
      label: 'common.createTime',
      minWidth: '200px',
      showOverflowTooltip: true
    }
  ];
  /**
   * 查询表单数据对象
   */
  public queryForm: Partial<{
    keywords: string;
    categoryId: number | null;
    companyId: number | null;
    status: ResourceStatusEnum | null;
  }> = {
    keywords: '',
    companyId: Number(this.$route.query.companyId),
    categoryId: null,
    status: null
  };
  /**
   * 表格查询表单配置
   */
  public queryFormOptions: Array<OsQueryItemOption> = [
    {
      type: 'Input',
      field: 'keywords',
      label: 'common.keyword',
      option: {
        placeholder: 'common.inputKeyWord'
      }
    },
    {
      type: 'Cascader',
      field: 'categoryId',
      label: 'supplierMaterial.category',
      option: {
        placeholder: 'supplierMaterial.selectCategory',
        filterable: true,
        clearable: true,
        collapseTags: true,
        showAllLevels: false,
        props: {
          checkStrictly: true,
          emitPath: false
        }
      },
      optionData: []
    },
    {
      type: 'Select',
      field: 'status',
      label: 'common.status',
      option: {
        placeholder: 'common.selectStatus'
      },
      optionData: (): Array<any> => {
        return [
          {
            label: translation('common.startUsing'),
            value: 1
          },
          {
            label: translation('common.disabled'),
            value: 2
          }
        ];
      }
    }
  ];
  /**
   * 表格操作栏
   */
  public tableToolbarOptions: Array<OperationOption> = [
    {
      type: 'primary',
      slot: 'start',
      label: 'button.add',
      operationType: 'add',
      icon: 'el-icon-circle-plus-outline',
      permissionCode: 'supplier:product:save',
      handleClick: (): void => {
        this.openDialog();
      }
    },
    {
      type: 'primary',
      slot: 'start',
      label: 'button.import',
      operationType: 'import',
      icon: 'el-icon-upload2',
      permissionCode: 'supplier:product:importData',
      handleClick: (): void => {
        this.openImportFileDialog();
      }
    },
    {
      type: 'primary',
      slot: 'end',
      label: 'button.using',
      operationType: 'using',
      icon: 'el-icon-open',
      disabled: true,
      permissionCode: 'supplier:product:editStatus',
      handleClick: (): void => {
        this.batchUpdateStatus(ResourceStatusEnum.using);
      }
    },
    {
      type: 'danger',
      slot: 'end',
      label: 'button.disabled',
      operationType: 'disabled',
      icon: 'el-icon-turn-off',
      plain: true,
      disabled: true,
      permissionCode: 'supplier:product:editStatus',
      handleClick: (): void => {
        this.batchUpdateStatus(ResourceStatusEnum.disabled);
      }
    }
  ];
  /**
   * 列操作配置
   * @deprecated 改变了编辑方式，不允许删除。暂时先保留功能代码
   */
  public rowOperationOption: RowOperation<SupplierProductResource> = {
    width: '200px',
    operations: [
      {
        operationType: 'edit',
        type: 'text',
        label: 'button.edit',
        icon: 'el-icon-edit',
        permissionCode: 'supplier:product:edit',
        handleClick: (row: SupplierProductResource): void => {
          this.openDialog(row);
        }
      },
      {
        operationType: 'delete',
        type: 'text',
        label: 'button.delete',
        icon: 'el-icon-delete',
        permissionCode: 'supplier:product:delete',
        handleClick: (row: SupplierProductResource): void => {
          this.delete(row);
        }
      }
    ]
  };
  /**
   * 分页配置
   */
  public pagintaionOption: Paging = {
    currentPage: 1,
    showCount: 10
  };
  /**
   * 表格总记录数
   */
  public totalData = 0;
  /**
   * dialog显示状态
   */
  public dialogVisible = false;

  public flagParams: FlagParams = {
    purchaseFlag: 1
  };
  /**
   * 编辑数据对象
   */
  public editRow: SupplierProductResource | null = null;
  /**
   * 导入dialog显示状态控制
   */
  public importDialogVisible = false;
  /**
   * 导入dialog 标题
   */
  public importTitle = 'supplierProduct.import';

  /**
   * 编辑价格
   */
  public editPriceValue = 0;

  /**
   * 编辑价格权限标识
   */
  public editPricePermissionCode = 'supplier:product:edit';

  /**
   * 表格选中数据
   */
  private selectedRows: Array<SupplierProductResource> = [];

  private companyId = Number(this.$route.query.companyId);

  public activated(): void {
    if (!isNaN(this.companyId) && this.companyId !== Number(this.$route.query.companyId)) {
      this.companyId = Number(this.$route.query.companyId);
      this.reloadData();
    }
  }

  public created(): void {
    this.loadMaterialCategories();
    this.loadData();
  }

  /**
   * 查询按钮点击事件
   */
  public handleQueryClick(): void {
    this.loadData();
  }

  /**
   * 分页事件
   */
  public handlePagination(): void {
    this.loadData();
  }

  /**
   * 表格行选中事件
   */
  public handleSelectionChange(rows: Array<SupplierProductResource>): void {
    this.selectedRows = rows;
  }

  /**
   * 加载表格数据
   */
  public loadData(): void {
    this.tableOption.loading = true;
    this.queryForm.companyId = this.companyId;
    supplierProductService
      .getList(this.queryForm as SupplierProductResource, this.pagintaionOption)
      .then((res: { data: SupplierProductResource[]; total: number }) => {
        res.data.forEach(item => {
          item.editPriceFlag = false;
        });

        this.tableOption.data = res.data;
        this.totalData = res.total;
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }

  /**
   * 重新加载
   */
  public reloadData(): void {
    this.pagintaionOption.currentPage = 1;
    (this.$refs.productTable as OsTable).clearSelection();
    this.selectedRows = [];
    this.loadData();
  }

  public getStatusClass(status: number): string {
    return getStatusClass(status);
  }

  public getStatusI18Key(status: number): string {
    return getStatusI18Key(status);
  }

  /**
   * dialog关闭事件
   */
  public handleDialogClosed(): void {
    this.editRow = null;
  }

  /**
   * 编辑保存成功事件
   * 更新表格数据
   */
  public handleEditSuccess(data: SupplierProductResource): void {
    const findItem = this.tableOption.data.find(row => row.id === data.id);
    Object.assign(findItem, data);
  }

  /**
   * 监听价格修改
   */
  public handlePriceChange(event: Event, data: SupplierProductResource): void {
    supplierProductService
      .editPrice(data)
      .then(() => {
        // Message.success(translation('operationRes.editSuccess'));
        data.editPriceFlag = false;
      })
      .catch((error: any) => {
        messageError(error);
      });
  }

  /**
   * 批量更新状态
   * @param status 状态
   */
  public batchUpdateStatus(status: ResourceStatusEnum): void {
    const idList = this.selectedRows.map(x => x.id);
    supplierProductService
      .batchUpdateStatus(idList, status)
      .then(() => {
        this.reloadData();
        Message.success(
          status === ResourceStatusEnum.using
            ? translation('common.usingSuccess')
            : translation('common.disabledSuccess')
        );
      })
      .catch(error => {
        messageError(error);
      });
  }
  /**
   * 下载导入模板
   */
  public downloadTemplate(): void {
    (this.$refs.importDialog as ImportFile).setDownloadLoading(true);
    supplierProductService
      .downloadTemplate(Number(this.$route.query.companyId))
      .then((blob: any) => {
        downloadFileByBlob(translation('supplierProduct.templateName'), blob);
      })
      .catch((error: string) => {
        Message.error(error);
      })
      .finally(() => {
        (this.$refs.importDialog as ImportFile).setDownloadLoading(false);
      });
  }
  /**
   * 上传成功事件
   */
  public handleImportSuccess(path: string): void {
    supplierProductService
      .import(Number(this.$route.query.companyId), path)
      .then(() => {
        this.reloadData();
        Message.success(translation('dialog.importSuccess'));
        this.importDialogVisible = false;
      })
      .catch((error: ApiResponse<ImportRes>) => {
        handleImportError(error);
        (this.$refs.importDialog as ImportFile).setLoading(false);
      });
  }

  /**
   * 选择产品回调
   * @param data 选择的产品
   */
  public handleSelectedProduct(data: Array<ProductList>): void {
    if (data.length === 0) {
      Message.warning('supplierProduct.selectPlatformProduct');
      return;
    }
    // 保存产品
    const saveProductIdList = data.map(item => item.id!);

    const saveData: any = {
      companyId: this.queryForm.companyId,
      productIdList: saveProductIdList
    };

    supplierProductService
      .bindProduct(saveData)
      .then(() => {
        this.reloadData();
        Message.success(translation('operationRes.addSuccess'));
        this.dialogVisible = false;
      })
      .catch((error: any) => {
        messageError(error);
      });
  }

  public getChargeUnitI18nKey(priceUnit: number): string {
    return 'chargeUnit.' + ChargeUnitEnum[priceUnit];
  }

  /**
   * 监视表格行选中数据变化
   * @param value 变化数据
   */
  @Watch('selectedRows')
  private handleSelectedChanged(value: Array<SupplierProductResource>): void {
    const allowedOpationArray = ['add', 'import', 'export'];
    this.tableToolbarOptions.forEach(button => {
      if (allowedOpationArray.indexOf(button.operationType) === -1) {
        button.disabled = value.length === 0;
      }
    });
    this.tableToolbarOptions = [...this.tableToolbarOptions];
  }
  /**
   * 删除
   */
  private delete(row: SupplierProductResource): void {
    showWarningConfirm(translation('tip.confirmDelete'))
      .then(async () => {
        try {
          await supplierProductService.delete(row.id);
          Message.success(translation('operationRes.deleteSuccess'));
          this.reloadData();
        } catch (error) {
          messageError(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelDelete'));
      });
  }
  /**
   * 批量删除
   */
  private batchDelete(): void {
    showWarningConfirm(translation('tip.confirmDelete'))
      .then(async () => {
        try {
          const idList = this.selectedRows.map(row => row.id);
          await supplierProductService.batchDelete(idList);
          Message.success(translation('operationRes.deleteSuccess'));
          this.reloadData();
        } catch (error) {
          messageError(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelDelete'));
      });
  }
  /**
   * 打开dialog
   * @param row 编辑数据
   */
  private async openDialog(row: SupplierProductResource | null = null): Promise<void> {
    if (!row) {
      this.editRow = row;
    } else {
      try {
        const supplier = await supplierProductService.getById(row.id);
        this.editRow = supplier;
      } catch (error) {
        messageError(error);
        return;
      }
    }

    this.dialogVisible = true;
  }
  /**
   * 打开导入窗口
   */
  private openImportFileDialog(): void {
    this.importDialogVisible = true;
  }

  /**
   * 获取材料分类
   */
  private async loadMaterialCategories(): Promise<void> {
    const categoryList = await classifyService.getTreeData();

    const queryOptionItem = this.queryFormOptions.find(option => option.field === 'categoryId');

    if (queryOptionItem) {
      queryOptionItem.optionData = classifyService.handleCascaderOption(categoryList);
    }
  }
}
